import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api/api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WorkOrderDetailXlsxExportService } from "../../services/export/work-order-detail-xlsx-export.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "../../services/core/toast.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import * as moment from "moment";
import { AcceptDeclineModalComponent } from "../../modals/accept-decline-modal/accept-decline-modal.component";
import { ModalController } from "@ionic/angular";
import { ArrayService } from "../../services/core/array.service";
import { StorageService } from "../../services/storage/storage.service";
import { WorkOrderService } from "../../services/api/work-order.service";
import { SelectInventoryModalComponent } from "../../modals/select-inventory-modal/select-inventory-modal.component";
import { RemarkService } from "../../services/api/remark.service";
import { TypeGroupService } from "../../services/api/type-group.service";
import { TimeSlotReactionType, TypeGroupType } from "../../enums";
import { NavigationService } from "src/app/services/core/navigation.service";
import {
  EmployeeTask,
  Qualification,
  Remark,
  SearchFunction,
  Team,
  TimeSlot,
  User,
  WorkOrderType,
  FilledInteractionForm,
  WorkOrder,
  HasTimes,
  Inventory,
  Company,
  TimeSlotReaction,
} from "src/app/interfaces";
import { SortService } from "../../services/core/sort.service";
import { SettingsService } from "src/app/services/api/settings.service";

@Component({
  selector: "app-work-order-detail-content",
  templateUrl: "./work-order-detail-content.component.html",
  styleUrls: ["./work-order-detail-content.component.scss"],
})
export class WorkOrderDetailContentComponent implements OnInit, OnDestroy {
  @Input() set Id(workOrderId: string) {
    const previousId = this.workOrderId;
    this.workOrderId = workOrderId;
    if (this.isInitialized && previousId != workOrderId) {
      this.loadWorkOrder();
    }
  }

  @Input() SplitViewMode;
  @Input() ModalMode: boolean = false;
  @Output() Reload = new EventEmitter<void>();
  @Output() Remove = new EventEmitter<void>();
  @Output() CloseView = new EventEmitter<void>();
  @Output() loading = new EventEmitter<void>();

  workOrderId = "";
  workOrder: WorkOrder;
  timeSlots: TimeSlot[] = [];
  timeslotReactions: TimeSlotReaction[] = [];
  tasks: EmployeeTask[] = [];
  qualifications: Qualification[] = [];
  workOrderTypeQualifications: Qualification[] = [];
  workOrderTypeMinutes: number = null;
  workOrderTypes: WorkOrderType[] = [];
  timeSlotUsers: User[] = [];
  timeSlotUserIds: string[] = [];
  hasDifferingAddress = false;
  customTask = null;
  selectedWorkOrderType: WorkOrderType;
  hasWorkTimes = false;
  isLoading = true;
  isInitialized = false;

  showFees: boolean = false;
  showApplications: boolean = false;
  showBillingNumber: boolean = false;
  externalApproval: boolean = false;
  ShowTimeSlotCustomHighlights: boolean = false;

  userSearchFunction: SearchFunction = this.arrayService.createSearchFunction(
    "FirstName",
    "LastName"
  );
  typeSearchFct = this.groupService.getTypeSearchFunction();
  isSaving = false;
  teams: Team[] = [];
  showInventory: boolean;
  userRemarks: { [UserId: string]: Remark[] } = {};
  selectedForm: FilledInteractionForm;
  triggerCollapseState: { [key: string]: boolean } = {};
  company: Company;
  TimeSlotReactionTypes = TimeSlotReactionType;
  highlightChanges = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private modalService: NgbModal,
    public xlsxExportService: WorkOrderDetailXlsxExportService,
    private translate: TranslateService,
    private toastService: ToastService,
    private modalController: ModalController,
    private arrayService: ArrayService,
    private storageService: StorageService,
    private workOrderService: WorkOrderService,
    private remarkService: RemarkService,
    private groupService: TypeGroupService,
    private navigation: NavigationService,
    private sortService: SortService,
    private settingsService: SettingsService
  ) {}

  async ngOnInit() {
    this.isInitialized = false;
    this.isLoading = true;
    this.triggerCollapseState = {};

    const data = await this.api.get('work-orders/init');

    this.qualifications = data.qualifications;
    this.workOrderTypeQualifications = data.qualifications;
    this.workOrderTypes = data.workOrderTypes;
    this.externalApproval = data.externalApproval;
    this.showInventory = data.showInventory;
    this.highlightChanges = data.highlightChanges;
    this.showFees = data.showFees;
    this.showBillingNumber = data.showBillingNumber;
    this.showApplications = data.showApplications;
    this.ShowTimeSlotCustomHighlights = data.showTimeSlotCustomHighlights;
    this.company = data.company;
    this.teams = data.teams;

    // Live
    this.clearWorkOrder();
    await this.loadWorkOrder();
    this.isLoading = false;
    this.loading.emit();
    this.isInitialized = true;
  }

  async ngOnDestroy() {}

  async loadWorkOrder(showSpinner= true) {
    if(showSpinner) {
      this.isLoading = true;
    }
    this.selectedForm = null;
    this.workOrder = await this.workOrderService.get(this.workOrderId);
    if (this.workOrder) {
      this.hasDifferingAddress =
        this.workOrder.City &&
        (this.workOrder.Latitude !== this.company.Latitude ||
          this.workOrder.Longitude !== this.company.Longitude);
      if (this.workOrder.WorkOrderType) {
        if (this.workOrder.WorkOrderType.Qualifications) {
          this.workOrderTypeQualifications =
            this.workOrder.WorkOrderType.Qualifications;
        }
        if (this.workOrder.WorkOrderType.EstimatedDuration) {
          this.workOrderTypeMinutes =
            this.workOrder.WorkOrderType.EstimatedDuration;
        }
        this.workOrder.AppSettings = this.workOrder.AppSettings || {};
      }
      this.userRemarks = await this.remarkService.getAllUserRemarksByTimeSlots(
        this.workOrder
      );
      await this.prepareTimeSlots();
    }
    
    await this.loadReactions();
    this.isLoading = false;
  }

  async prepareTimeSlots() {
    const qualificationIds = this.workOrder.QualificationIds || [];
    const numWorker = this.workOrder.NumWorker || 0;
    const timeSlots =
      this.workOrder.TimeSlots?.length > 0 ? [...this.workOrder.TimeSlots] : [];

    const newTimeSlots = [];
    const qualificationsMap = this.arrayService.toMap(
      this.workOrderTypeQualifications
    );

    // delete this.workOrder.TimeSlots;
    for (const qualificationId of qualificationIds) {
      newTimeSlots.push({
        QualificationId: qualificationId,
        Qualification: qualificationsMap[qualificationId],
        WorkOrderId: this.workOrder.Id,
        Description: this.workOrder.Description,
        Name: this.workOrder.Name,
        CustomAbbreviation: this.workOrder.CustomAbbreviation,
      });
    }

    for (let i = newTimeSlots.length; i < numWorker; i++) {
      newTimeSlots.push({
        WorkOrderId: this.workOrder.Id,
        Description: this.workOrder.Description,
        Name: this.workOrder.Name,
        CustomAbbreviation: this.workOrder.CustomAbbreviation,
      });
    }
    for (let i = 0; i < newTimeSlots.length; i++) {
      const newTimeSlot = newTimeSlots[i];
      const index = timeSlots.findIndex((ts: TimeSlot) =>
        newTimeSlot.QualificationId
          ? ts.QualificationId === newTimeSlot.QualificationId
          : !ts.QualificationId
      );
      if (index > -1) {
        newTimeSlots[i] = timeSlots[index];
        timeSlots.splice(index, 1);
      }
    }
    for (const timeSlot of timeSlots) {
      newTimeSlots.push(timeSlot);
    }
    this.sortService.sortByComparators(
      newTimeSlots,
      ...this.sortService.TIMESLOT_COMPARATORS
    );
    this.timeSlots = newTimeSlots;
    this.hasWorkTimes = false;
    for (const ts of this.timeSlots) {
      if (ts.WorkTime) {
        this.hasWorkTimes = true;
      }
    }
    await this.updateTimeSlotUsers();
  }

  async createCopy() {
    const newWorkorder = await this.workOrderService.copy(this.workOrder);
    if (this.SplitViewMode) {
      await this.CloseView.emit();
    } else {
      await this.router.navigate(["/work-orders/" + newWorkorder.Id]);
    }
  }

  clearWorkOrder() {
    this.workOrder = this.workOrder || {};
    this.workOrder.StartTime = null;
    this.workOrder.EndTime = null;
    this.workOrder.SpecialFields = this.workOrder.SpecialFields || {};
    this.workOrder.Tasks = this.workOrder.Tasks || [];
    this.workOrder.Triggers = this.workOrder.Triggers || [];
    this.userRemarks = {};
    this.isLoading = false;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  addTask(task: EmployeeTask) {
    this.workOrder.Tasks.push({ Name: task.Name, Employees: [] });
  }

  addCustomTask(taskName: string) {
    if (taskName !== null) {
      this.workOrder.Tasks.push({ Name: taskName, Employees: [] });
    }
    this.customTask = null;
  }

  removeTask(task: EmployeeTask) {
    const index: number = this.workOrder.Tasks.indexOf(task);
    if (index !== -1) {
      this.workOrder.Tasks.splice(index, 1);
    }
  }

  get AvailableQualifications(): Qualification[] {
    return this.workOrderTypeQualifications.length === 0
      ? this.qualifications
      : this.workOrderTypeQualifications;
  }

  updateAddress() {
    if (!this.hasDifferingAddress) {
      this.workOrder.Street = this.company.Street;
      this.workOrder.Zip = this.company.Zip;
      this.workOrder.City = this.company.City;
    }
  }

  async save(goBack: boolean = false, modal: boolean = false) {
    this.isSaving = true;
    const response = modal || (await this.checkWorkTimes());
    if (response) {
      if (!this.workOrder.StartTime) {
        this.isSaving = false;
        return this.toastService.presentError(
          this.translate.instant('die_muss_eine_start_und')
        );
      } else if (
        this.workOrder.StartTime &&
        this.workOrder.EndTime &&
        this.workOrder.StartTime >= this.workOrder.EndTime
      ) {
        this.isSaving = false;
        return this.toastService.presentWarning(
          this.translate.instant('die_startzeit_darf_nicht_spter')
        );
      }
      this.workOrder.QualificationIds = this.timeSlots
        .filter((timeSlot) => timeSlot.QualificationId)
        .map((timeSlot) => timeSlot.QualificationId);
      this.workOrder.TimeSlots = JSON.parse(JSON.stringify(this.timeSlots));
      await this.workOrderService.save(
        this.workOrder,
        this.translate.instant(
          "pages.work_order.work_order_detail.saveSuccessMsg"
        )
      );
      this.Reload.emit();
      if (goBack && !this.SplitViewMode) {
        if(this.ModalMode) {
          return this.CloseView.emit();
        }
        return this.goBack();
      }

      if (this.workOrderId === this.workOrder.Id) {
        if (!modal) {
          await this.loadWorkOrder(false);
        }
      } else {
        await this.router.navigate(["/work-orders/" + this.workOrder.Id]);
      }
    }
    this.isSaving = false;
  }

  goBack() {
    if(this.ModalMode) {
      return this.CloseView.emit();
    }
    if (this.SplitViewMode) {
      return;
    }
    this.navigation.back();
  }

  async delete() {
    const response = await this.checkWorkTimes(this.translate.instant('trotzdem_lschen'));
    if (response) {
      await this.workOrderService.destroy(
        this.workOrder,
        this.translate.instant(
          "pages.work_order.work_order_detail.deleteSuccessMsg"
        )
      );
      this.goBack();
      this.Remove.emit();
    }
  }

  async handleTimeSlotAdd() {
    this.workOrder.NumWorker = this.timeSlots.length;
  }

  async handleTimeSlotRemove() {
    this.workOrder.NumWorker = this.timeSlots.length;
    await this.save();
    await this.loadReactions();
  }

  addNewQualification() {
    return (name: string) => {
      return this.api.post("qualifications", { Name: name }) as Qualification;
    };
  }

  async updateTimeSlotUsers() {
    const persons = []; //for tasks
    const userIds = []; //for maillist
    for (let slot of this.timeSlots) {
      if (slot.User) {
        persons.push(slot.User);
        userIds.push(slot.User.Id);
      }
    }
    this.timeSlotUsers = persons;
    this.timeSlotUserIds = userIds;
  }

  async setWorkOrderType(workOrderType: WorkOrderType) {
    this.workOrderService.createOrUpdateFromType(workOrderType, this.workOrder);
    this.workOrderTypeQualifications = workOrderType.Qualifications;
    await this.checkTeam();
    await this.prepareTimeSlots();
    this.updateAppSettings(workOrderType.AppSettings);
    await this.save();
  }

  clearWorkOrderType() {
    this.workOrderTypeQualifications = [];
  }

  openSetWorkOrderTypeModal(modal: any, workOrderType: WorkOrderType) {
    this.selectedWorkOrderType = workOrderType;
    if (workOrderType) {
      this.modalService.open(modal, {
        windowClass: "modal-animated",
        centered: true,
      });
    }
  }

  async closeModal(modal: any, overWrite: boolean) {
    if (overWrite) {
      await this.setWorkOrderType(this.selectedWorkOrderType);
    }
    this.selectedWorkOrderType = undefined;
    modal.close();
  }

  async deleteReaction(reaction: TimeSlotReaction) {
    const response = await this.checkWorkTimes();
    if (response) {
      await this.api.delete("time-slots/reaction/" + reaction.Id);
      await this.loadReactions();
    }
  }

  updateTime(newTime: HasTimes) {
    this.workOrder.StartTime = newTime.StartTime;
    this.workOrder.EndTime = newTime.EndTime;
  }

  async acceptTimeSlot(timeSlot: TimeSlot) {
    if (this.externalApproval) {
      await this.toastService.presentInfo(this.translate.instant('der_zeitslot_wurde_besttigt'));
      const userReaction: TimeSlotReaction = await this.api.post(
        "time-slots/" + timeSlot.Id + "/approve",
        {}
      );
      if (userReaction && userReaction.Id) {
        timeSlot.AcceptReaction = userReaction;
      }
      return this.loadReactions();
    }
  }
  async declineTimeSlot(timeSlot: TimeSlot) {
    if (this.externalApproval) {
      await this.toastService.presentInfo(this.translate.instant('der_zeitslot_wurde_abgelehnt'));
      const userReaction = await this.api.post("time-slots/" + timeSlot.Id + "/decline", {});
      if (userReaction && userReaction.Id) {
        timeSlot.User = null;
        timeSlot.UserId = null;
      }
      return this.loadReactions();
    }
  }

  async pokeUser(timeSlot: TimeSlot) {
    if (this.workOrder.Draft) {
      await this.toastService.presentWarning(
        this.translate.instant('im_draft_werden_keine_benachrichtigungen')
      );
    } else {
      await this.toastService.presentInfo(
        this.translate.instant('die_benachrichtigung_wurde_versendet')
      );
      await this.pushTimeslot(this.workOrder, timeSlot);
    }
  }

  async pokeAllUser() {
    if (this.workOrder.Draft) {
      return this.toastService.presentWarning(
        this.translate.instant('im_draft_werden_keine_benachrichtigungen')
      );
    }
    await this.toastService.presentInfo(
      this.translate.instant('die_ausstehenden_benachrichtigungen_wurden_versendet')
    );
    this.timeSlots.forEach((timeslot) =>
      this.pushTimeslot(this.workOrder, timeslot)
    );
  }

  async pokeForms() {
    return this.workOrderService.sendFormReminder(this.workOrder);
  }

  private async pushTimeslot(workOrder: WorkOrder, timeslot: TimeSlot) {
    if (
      !timeslot ||
      timeslot.AcceptReaction ||
      !timeslot.UserId ||
      !timeslot.Id
    ) {
      return;
    }
    await this.api.post("push/" + timeslot.UserId, {
      Title:
        workOrder.Name +
        " " +
        moment(timeslot.StartTime).format("DD.MM.YYYY HH:mm"),
      Message: this.translate.instant(
        "pages.work_order.work_order_detail.pokeMsg"
      ),
      Link: null,
      Data: {
        detailsTopic: "tabs/time-slot-detail",
        detailsId: timeslot.Id,
        companyId: this.storageService.getCompanyId(),
      },
    });
  }

  async checkTeam() {
    if (this.workOrder.Teams && this.workOrder.Teams.length > 0) {
      for (let slot of this.timeSlots) {
        if (slot.User) {
          if (!slot.User.TeamMembers) {
            slot.UserId = undefined;
            slot.User = undefined;
          } else {
            let inAnyTeam = false;
            for (let team of this.workOrder.Teams) {
              if (
                JSON.stringify(slot.User.TeamMembers).indexOf(team.Id) != -1
              ) {
                inAnyTeam = true;
              }
            }
            if (!inAnyTeam) {
              slot.UserId = undefined;
              slot.User = undefined;
            }
          }
        }
      }
    }
  }

  async loadReactions() {
    this.timeslotReactions = await this.workOrderService.getAllReactions(
      this.workOrder
    );
  }

  async checkWorkTimes(
    okText: string = this.translate.instant('trotzdem_speichern')
  ): Promise<boolean> {
    if (!this.hasWorkTimes) {
      return true;
    }
    const modal = await this.modalController.create({
      componentProps: {
        Message: this.translate.instant('es_sind_schon_arbeitszeiten_gestartet'),
        OkText: okText,
      },
      component: AcceptDeclineModalComponent,
      cssClass: "custom-ion-modal",
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    return !!response.data;
  }

  async closeSplitView() {
    await this.CloseView.emit();
  }

  updateAppSettings(settings: any) {
    this.workOrder.AppSettings = JSON.parse(JSON.stringify(settings));
  }

  async deleteInventory(inventory: Inventory) {
    return this.workOrderService.deleteInventory(this.workOrder, inventory);
  }

  async openInventoryModal() {
    const selectionList = await this.workOrderService.getInventorySelectionList(
      this.workOrder
    );
    const modal = await this.modalController.create({
      componentProps: {
        Item: this.workOrder,
        SelectionList: selectionList,
      },
      component: SelectInventoryModalComponent,
      cssClass: "custom-ion-modal",
    });
    modal.onDidDismiss().then(async (response) => {
      if (response.data && response.data.Id) {
        await this.workOrderService.saveInventory(
          this.workOrder,
          response.data
        );
      }
    });
    await modal.present();
  }

  updateRemarks(remarks: { [id: string]: Remark[] }) {
    this.userRemarks = Object.assign({}, this.userRemarks, remarks);
  }

  isToday() {
    return moment().isSameOrAfter(this.workOrder.StartTime, "d");
  }

  hasTriggers() {
    return this.workOrder?.Triggers && this.workOrder.Triggers.length > 0;
  }

  showFormReminder() {
    return this.isToday() && this.hasTriggers();
  }
}
